<template>
  <main class="main">
    <section v-if="order" class="section">
      <div class="site-container">
        <div class="order-success">
          <div class="order-success__info-top">
            <div class="order-success__info-block info-block info-block--success">
              <p>
                Спасибо, ваш заказ принят. Ожидайте доставки в назначенный день.<br>
                Информация о заказе будет продублированана ваш e-mail.
              </p>
            </div>


            <HelpManager></HelpManager>
          </div>

          <div class="order-success__links links-block">
            <router-link to="/" class="links-block__link btn btn--non-target" href="#">Главная</router-link>
            <router-link to="/personal" class="links-block__link btn" href="#">Личный кабинет</router-link>
          </div>

          <div class="order-success__requisites requisites-block">
            <div class="requisites-block__items">
              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Сумма к оплате</span>
                <span class="requisites-block__pair-value requisites-block__pair-value--bold">{{order.sum}}&nbsp;₽</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">ФИО контактного лица</span>
                <span class="requisites-block__pair-value">{{order.fio}}</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Контактный телефон</span>
                <span class="requisites-block__pair-value">{{order.phone}}</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">E-mail</span>
                <span class="requisites-block__pair-value">{{order.email}}</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Адрес доставки</span>
                <span class="requisites-block__pair-value">{{order.address}}</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Дата доставки</span>
                <span class="requisites-block__pair-value">{{order.date}}</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Время приема отгрузок</span>
                <span class="requisites-block__pair-value">{{order.time}}</span>
              </p>

              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Комментарий к заказу</span>
                <span class="requisites-block__pair-value">{{order.comment}}</span>
              </p>
            </div>
          </div>

          <div class="order-success__info-bottom">
            <div class="order-success__info-block info-block">
              <h3>Информация о доставке</h3>
              <p>
                Задача организации, в особенности же убеждённость некоторых оппонентов в значительной степени обусловливает важность прогресса профессионального сообщества. Идейные соображения высшего порядка, а также глубокий уровень погружения однозначно определяет каждого участника как способного принимать собственные решения касаемо анализа существующих паттернов поведения.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import {ref, onUpdated, watch} from 'vue'
import { computed } from 'vue'
import {useGetToken} from "@/use/getToken";
import {useRouter} from "vue-router";
import HelpManager from "../components/app/HelpManager";

export default {
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    order() {
      return this.$store.getters.orderInfo(this.$route.params.id);
    }
  },
  created() {
    if (this.$store.getters.orderInfo(this.$route.params.id) == undefined)
      this.getInfo();
  },
  components: {HelpManager},
  methods: {
    async getInfo() {
      await this.$store.dispatch({type: 'fetchOrderInfo', id:this.$route.params.id})
    }
  },
}
</script>

