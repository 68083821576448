<template>
  <main class="main">
    <section class="section">
      <div class="site-container">
        <div class="info-block info-block--success">
          <p>
            Спасибо, ваша заявка на регистрацию принята. О подтверждении аккаунта вы получите письмо на ваш e-mail.
          </p>
        </div>

        <img src="img/img-registration-finished-new.png" alt="Ваша заявка на регистрацию принята">

        <router-link to="/" class="btn">Главная</router-link>
      </div>
    </section>
  </main>
</template>

<script>
import {ref, onUpdated, watch} from 'vue'
import { computed } from 'vue'
import {useGetToken} from "@/use/getToken";
import {useRouter} from "vue-router";

export default {
  mounted() {
    this.$store.dispatch('Loader');
  },
  components: {},
  methods: {},
}
</script>

