import axios from "axios";
export default {
    state: {
        requisites: false,
        documents: false
    },
    mutations: {
        requisites(state, requisites) {
            state.requisites = requisites.item;
        },
        documents(state, documents) {
            state.documents = documents.items;
        }
    },
    getters: {
        requisites: s => s.requisites,
        documents: s => s.documents
    },
    actions: {
        async fetchRequisites({dispatch, commit}) {
            let response = await axios('/local/api/company/requisites').then(function (response) {
                let requisites = response.data; // читать тело ответа в формате JSON
                commit('requisites', requisites);
            }); // завершается с заголовками ответа
        },
        async fetchDocuments({dispatch, commit}) {
            let response = await axios('/local/api/company/documents').then(function (response) {
                let documents = response.data; // читать тело ответа в формате JSON
                commit('documents', documents);
            }); // завершается с заголовками ответа
        }
    }
}
