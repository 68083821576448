<template>
  <main class="main">
    <section class="section">
      <div class="site-container">
        <div class="orders-requests">
          <div class="orders-requests__summary">
            <div class="orders-requests__summary-data">
              <p class="orders-requests__summary-data-pair">
                <span class="orders-requests__summary-data-name orders-requests__summary-data-name--accent">Баланс</span>
                <span class="orders-requests__summary-data-value orders-requests__summary-data-value--accent">{{ orders.balans }}&nbsp;₽</span>
              </p>

              <p class="orders-requests__summary-data-pair">
                <span class="orders-requests__summary-data-name">Отгружено на</span>
                <span class="orders-requests__summary-data-value">{{ orders.complete }}&nbsp;₽</span>
              </p>

              <p class="orders-requests__summary-data-pair">
                <span class="orders-requests__summary-data-name">Оплачено</span>
                <span class="orders-requests__summary-data-value">{{ orders.pay }}&nbsp;₽</span>
              </p>
            </div>

            <router-link to="/reconciliation" class="orders-requests__summary-btn btn btn--non-target" href="#">Запросить акт сверки</router-link>
          </div>

          <div class="orders-requests__list-head">
            <h3 class="orders-requests__list-heading">Мои заказы</h3>
            <button @click="openModal" class="orders-requests__list-filter-toggle">{{filter?filter:'За все время'}}</button>
          </div>

          <div class="orders-requests__list">
            <template v-for="item in items">
              <router-link :to="'/orderdetail/'+item.id" class="orders-requests__item" :class="{'orders-requests__item--pale': item.status_id=='C'}" href="#">
                <div class="orders-requests__item-row">
                  <span class="orders-requests__item-number">№{{ item.number }}</span>
                  <span class="orders-requests__item-status " :class="getStatusCode(item.status_id)">{{ item.status }}</span>
                </div>

                <div class="orders-requests__item-row">
                  <span class="orders-requests__item-date">от {{item.date_create}}</span>
                  <span class="orders-requests__item-price">{{item.sum}}&nbsp;₽</span>
                </div>

                <div class="orders-requests__item-row">
                  <template v-if="item.sum > 0 && item.sum == item.sumpaid">
                    <span class="order_pay_all">Оплачено</span>
                  </template>
                  <template v-if="item.sumpaid == 0">
                    <span class="order_pay_not">Не оплачено</span>
                  </template>
                  <template v-if="item.sumpaid > 0 && item.sum > item.sumpaid">
                    <span class="order_pay_partly">Оплачено частично</span>
                  </template>
                </div>

                <span v-if="item.comments" class="orders-requests__alert">
                                {{item.comments}}
                            </span>
              </router-link>
            </template>
          </div>

          <p v-if="typeof items != 'undefined' && (!items || items.length == 0)">
            Заказы на найдены
          </p>
          <p v-if="typeof items == 'undefined'">
            Загрузка...
          </p>
        </div>
      </div>
    </section>
  </main>
  <div class="modal" :class="modalOverlay">
    <div class="modal__body" id="modalOrdersSort" :class="targetModal">
      <button @click="closeModals" class="modal__close-btn"></button>

      <h3 class="modal__heading">Выбрать период</h3>

      <div class="modal__body-content">
        <div class="date-input">
          <VueDatePicker placeholder="Начало пероида" v-model="from" locale="ru" format="dd/MM/yyyy" date-picker auto-apply></VueDatePicker>
<!--          <input class="date-input__input hidden-input" type="date" name="modal-dates-sort" id="modalDate1">-->
<!--          <label @click="begin" class="date-input__label" for="modalDate1">Начало периода</label>-->
        </div>

        <div class="date-input">
          <VueDatePicker placeholder="Конец периода" v-model="to" locale="ru" format="dd/MM/yyyy" date-picker auto-apply></VueDatePicker>
<!--          <input class="date-input__input hidden-input" type="date" name="modal-dates-sort" id="modalDate2">-->
<!--          <label class="date-input__label" for="modalDate2">Конец периода</label>-->
        </div>
      </div>

      <button @click="setfilter" class="modal__btn modal__ok-btn btn">Ок</button>
    </div>
  </div>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'

export default {
  data: () =>  ({
    statuses: {
      'OTHER': 'orders-requests__item-status--pending',
      'F': 'orders-requests__item-status--done',
      'S': 'orders-requests__item-status--way',
      'C': 'orders-requests__item-status--canceled',
      'V': 'orders-requests__item-status--canceled'
    },
    modalOverlay: false,
    targetModal: false,
    filter: false,
    from: '',
    to:'',
    items: false
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    orders() {
      let orders = this.$store.getters.orders;
      this.items = this.filterOrders(orders.items);
      return orders;
    }
  },
  methods: {
    async showOrders() {
      await this.$store.dispatch('fetchOrders')
    },
    getStatusCode(id) {
      if (typeof this.statuses[id] == 'string')
        return this.statuses[id];
      else
        return this.statuses['OTHER'];
    },
    openModal() {
      //let body = $('body');
      //body.addClass('fixed');
      this.modalOverlay='modal--open';
      this.targetModal='modal__body--open';
    },
    closeModals() {
      //let body = $('body');
      //body.removeClass('fixed');
      this.modalOverlay='';
      this.targetModal='';
    },
    filterOrders(orders) {
      if (this.from) {
        let items = [];
        for (let i in orders) {
          if (orders[i].timestamp >= this.from.setHours(0, 0, 0, 0)/1000)
            items.push(orders[i]);
        }
        orders = items;
      }
      if (this.to) {
        let items = [];
        for (let i in orders) {
          if (orders[i].timestamp < this.to.setHours(23, 59, 59, 0)/1000)
            items.push(orders[i]);
        }
        orders = items;
      }
      return orders;
    },
    setfilter() {
      this.items = this.filterOrders(this.$store.getters.orders.items)
      if (this.from || this.to)
        this.filter = this.formatDate(this.from)+" - "+this.formatDate(this.to);
      else
        this.filter = false;
      this.closeModals();
    },
    formatDate(date) {
      if (!date)
        return '';
      return date.getDate()+"/"+date.getMonth()+"/"+date.getFullYear();
    }
  },
  created() {
    this.showOrders();
  },
  components: {VueDatePicker },
}
</script>

