<template>
    <section v-if="requisites" class="section">
      <div class="site-container">
        <div class="requisites-block">
          <h2 class="requisites-block__heading section__heading">Реквизиты</h2>

          <div class="requisites-block__items">
            <p class="requisites-block__pair">
              <span class="requisites-block__pair-name">Полное наименование организации</span>
              <span class="requisites-block__pair-value">{{requisites.name}}</span>
            </p>

            <p class="requisites-block__pair">
              <span class="requisites-block__pair-name">Юридический адрес</span>
              <span class="requisites-block__pair-value">{{requisites.address}}</span>
            </p>

            <p class="requisites-block__pair">
              <span class="requisites-block__pair-name">Почтовый адрес</span>
              <span class="requisites-block__pair-value">{{requisites.zip}}</span>
            </p>

            <p class="requisites-block__pair">
              <span class="requisites-block__pair-name">E-mail</span>
              <span class="requisites-block__pair-value">{{requisites.email}}</span>
            </p>

            <p class="requisites-block__pair">
              <span class="requisites-block__pair-name">ИНН</span>
              <span class="requisites-block__pair-value">{{requisites.inn}}</span>
            </p>

            <p class="requisites-block__pair">
              <span class="requisites-block__pair-name">КПП</span>
              <span class="requisites-block__pair-value">{{requisites.kpp}}</span>
            </p>

            <p class="requisites-block__pair">
              <span class="requisites-block__pair-name">Телефон</span>
              <span class="requisites-block__pair-value">{{requisites.phone}}</span>
            </p>
          </div>
        </div>
      </div>
    </section>
    <section v-if="documents" class="section">
      <div class="site-container">
        <div class="company-documents">
          <h2 class="company-documents__heading section__heading">Документы</h2>

          <div class="company-documents__items">
            <a v-for="item in documents" class="company-documents__item document" :href="item.src">
              <span class="document__name">{{item.name}}</span>
              <span class="document__size">{{item.size}}</span>
            </a>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import {ref, onUpdated, watch} from 'vue'
import { computed } from 'vue'
import {useGetToken} from "@/use/getToken";
import {useRouter} from "vue-router";

export default {
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    requisites() {
      return this.$store.getters.requisites;
    },
    documents() {
      return this.$store.getters.documents;
    }
  },
  methods: {
    async showCompany() {
      await this.$store.dispatch('fetchRequisites')
      await this.$store.dispatch('fetchDocuments')
    }
  },
  created() {
    this.showCompany();
  },
}
</script>

