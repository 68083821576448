import axios from "axios";
export default {
    state: {
        refund: false,
        refundDetail: false
    },
    mutations: {
        refund(state, refund) {
            state.refund = refund.items;
        },
        refundDetail(state, refundDetail) {
            state.refundDetail = refundDetail.item;
        }
    },
    getters: {
        refund: s => s.refund,
        refundDetail: s => s.refundDetail
    },
    actions: {
        async fetchRefund({dispatch, commit}) {
            let response = await axios('/local/api/personal/refund').then(function (response) {
                let refund = response.data; // читать тело ответа в формате JSON
                commit('refund', refund);
            }); // завершается с заголовками ответа

        },
        async fetchRefundDetail({dispatch, commit}, data) {
            let response = await axios('/local/api/personal/refund/'+data.id).then(function (response) {
                let refundDetail = response.data; // читать тело ответа в формате JSON
                commit('refundDetail', refundDetail);
            }); // завершается с заголовками ответа

        }
    }
}
