import axios from 'axios'

export default {
    state: {
        about_header: '',
        about_text: '',
        about_image: '',
        banner_header: '',
        banner_text: '',
        banner_image: '',
        banner_url: ''
    },
    mutations: {
        about(state, data) {
            state.about_header = data.about.header;
            state.about_text = data.about.text;
            state.about_image = data.about.image;
            state.banner_header = data.banner.header;
            state.banner_text = data.banner.text;
            state.banner_image = data.banner.image;
            state.banner_url = data.banner.url;

        }
    },
    getters: {
        about_header: s => s.about_header,
        about_text: s => s.about_text,
        about_image: s => s.about_image,
        banner_header: s => s.banner_header,
        banner_text: s => s.banner_text,
        banner_image: s => s.banner_image,
        banner_url: s => s.banner_url
    },
    actions: {
        fetchHomeAbout({dispatch, commit}) {
            let response = axios('https://abcadmin.ru/local/api/about').then(function (data) {
                commit('about', data.data);
            }).catch(function(err){
                alert(err)
            });
        }
    }
}
