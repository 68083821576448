<template>
  <template v-if="isAuth">
    <header class="header">
      <div class="site-container">
        <div class="header__wrapper">
          <a v-if="!$route.meta.showLogo" class="header__return-btn" @click="prevPage"></a>
          <img v-if="$route.meta.showLogo" class="header__logo-block" alt="" src="/img/logo-header.svg" width="86" height="40">
          <h1 v-if="$route.meta.title" class="header__title" :class="!$route.meta.bigTitle?' header__title--small':''">{{ $route.meta.title }}</h1>
          <router-link to="/personal" :title="login" class="header__personal-link" href="#">{{name}}</router-link>
        </div>
      </div>
    </header>
    <main class="main" :class="{'main--w-cart':basket_quantity>0}">
          <router-view />
    </main>
    <FlyBasket v-if="!hideBasket"></FlyBasket>
    <Footer></Footer>
  </template>
</template>

<script>
import Footer from '../components/app/Footer'
import FlyBasket from '../components/app/FlyBasket'
export default {
  name: 'main-layout',
  data: () => ({

  }),
  computed: {
    login() {
      return this.$store.getters.login;
    },
    name() {
      if (typeof this.$store.getters.user == 'object')
        return this.$store.getters.user.UF_NAME;
    },
    isAuth() {
      return this.$store.getters.isAuth;
    },
    hideBasket() {
      if (this.$route.path == '/order' || this.$route.path == '/basket')
        return true;
      return false
    },
    basket_quantity() {
      return this.$store.getters.flyBasket.quantity;
    }
  },
  mounted() {
    if (this.$store.getters.isAuth === false) {
    //  this.$router.push('/login');
    }
  },
  methods: {
    prevPage() {
      this.$router.go(-1)
    }
  },
  components: {
  Footer,
    FlyBasket
  }
}
</script>
