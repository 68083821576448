import axios from "axios";
export default {
    state: {
        homeSale: false
    },
    mutations: {
        slider(state, slider) {
            state.homeSale = slider.items;
        }
    },
    getters: {
        homeSale: s => s.homeSale
    },
    actions: {
        async fetchHomeSale({dispatch, commit}) {
            let response = await axios('/local/api/catalog/sale?top=Y').then(function (response) {
                let slider = response.data; // читать тело ответа в формате JSON
                commit('slider', slider);
            }); // завершается с заголовками ответа

        }
    }
}
