<template>
  <header class="header">
    <div class="site-container">
      <div class="header__wrapper">
        <a class="header__return-btn" href="#" @click="prevPage"></a>

        <h1 class="header__title header__title--small">{{ $route.meta.title }}</h1>
      </div>
    </div>
  </header>
  <main class="main">
    <router-view />
  </main>
  <Footer></Footer>
</template>

<script>
import Footer from '../components/app/Footer'

export default {
  name: 'empty-layout',
  props: [
    'title'
  ],
  data: () => ({

  }),
  methods: {
    prevPage() {
      this.$router.go(-1)
    }
  },
  components: {
    Footer
  }
}
</script>