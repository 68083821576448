<template>
  <div v-if="basket.quantity > 0" class="footer-cart">
    <div class="site-container">
      <router-link to="/basket" class="footer-cart__content" href="#">
        <div class="footer-cart__counter-block">
          <h3 class="footer-cart__heading">Корзина</h3>

          <span class="footer-cart__counter">{{ basket.quantity }} {{ declOfNum(basket.quantity, ['товар','товара','товаров']) }}</span>
        </div>

        <p class="footer-cart__price">{{ basket.price }}&nbsp;₽</p>
      </router-link>
    </div>
  </div>
</template>
<script>

export default {
  props: {
    type: ''
  },
  data: () => ({

  }),
  computed: {
    basket() {
      return this.$store.getters.flyBasket;
    }
  },
  methods: {
    async showBasket() {
      await this.$store.dispatch('fetchFlyBasket')
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
  created() {
    this.showBasket();
  },
  components: {

  }
}
</script>