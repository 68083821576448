import axios from "axios";
export default {
    state: {
        slider: false
    },
    mutations: {
        slider(state, slider) {
            state.slider = slider.items;
        }
    },
    getters: {
        slider: s => s.slider
    },
    actions: {
        async fetchSlider({dispatch, commit}) {
            let response = await axios('/local/api/slider').then(function (response) {
                let slider = response.data; // читать тело ответа в формате JSON
                commit('slider', slider);
            }); // завершается с заголовками ответа
        }
    }
}
