import axios from "axios";
export default {
    state: {
        homeNew: false
    },
    mutations: {
        slider(state, slider) {
            state.homeNew = slider.items;
        }
    },
    getters: {
        homeNew: s => s.homeNew
    },
    actions: {
        async fetchHomeNew({dispatch, commit}) {
            let response = await axios('/local/api/catalog/new?top=Y').then(function (response) {
                let slider = response.data; // читать тело ответа в формате JSON
                commit('slider', slider);
            }); // завершается с заголовками ответа

        }
    }
}
