<template>
  <main class="main">
    <section class="section">
      <div class="site-container">
        <div class="auth-reg">
          <img class="auth-reg__logo" src="img/logo-preloader.svg" alt="Логотип ABC" width="211" height="56">

          <p class="auth-reg__text">
            Если вы ещё не работали с нами,<br> пройдите регистрацию
          </p>

          <div class="auth-reg__links links-block">
            <router-link class="links-block__link btn btn--non-target" to="/login">Авторизация</router-link>
            <router-link class="links-block__link btn" to="/registration">Регистрация</router-link>
          </div>

          <div class="auth-reg__info-block info-block info-block--success info-block--centered">
            <h3>Помощь по любым вопросам</h3>
            <p>
              Обращайтесь к нам
            </p>
            <a :href="'tel:'+phone_clear">{{phone}}</a>
          </div>

          <div class="auth-reg__links links-block">
            <router-link class="links-block__link btn btn--non-target" to="/about">Информация о компании ABC</router-link>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  props: [
  ],
  data: () => ({
  }),
  created() {

  },
  computed: {
    phone() {
      return this.$store.getters.phone;
    },
    phone_clear() {
      if (typeof this.$store.getters.phone == 'undefined')
        return '';
      return this.$store.getters.phone.replace(/[^0-9+.]/g, '');
    }
  },
  mounted() {

    this.$store.dispatch('Loader');
  },
  beforeRouteLeave(to, from) {

    // called when the route that renders this component is about to be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },
  methods: {
  },
  components: {

  }
}
</script>

