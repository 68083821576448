<template>
    <section v-if="refund" class="section">
      <div class="site-container">
        <div class="order-request-detail">
          <h2 class="order-request-detail__heading section__heading">{{ refund.title }}</h2>

          <div class="order-request-detail__total-short">
            <div class="order-request-detail__total-short-row">
              <span class="order-request-detail__total-short-sum-text">Итого</span>
              <span class="order-request-detail__total-short-sum-value">{{ refund.sum }}</span>
            </div>

            <div class="order-request-detail__total-short-row">
              <span class="order-request-detail__total-short-date">{{ refund.date_create }}</span>
              <span class="order-request-detail__total-short-status" :class="getStatusCode(refund.status_id)">{{ refund.status }}</span>
            </div>

            <div class="order-request-detail__total-short-row">
              <span class="order-request-detail__total-short-count">{{ refund.quantity }} {{ declOfNum(refund.quantity, ['товар','товара','товаров']) }}</span>
            </div>
          </div>

          <div v-if="basket" class="order-request-detail__products">
            <template v-for="item in basket">
              <div v-if="item" class="order-request-detail__product product" :class="{'product--disabled':false}">
                <div class="product__left">
                  <div class="product__img-block">
                    <img v-if="item.product.image" class="product__img" :src="item.product.image">
                  </div>

                  <div class="product__left-bottom">
                    <span v-if="item.product.untappd" class="product__rating">{{item.product.untappd}}</span>

                    <div class="product__prices">
                      <span class="product__price">{{item.order.final_price}}&nbsp;₽</span>
                      <span v-if="item.order.price > item.order.final_price" class="product__price product__price--old">{{item.order.price}}&nbsp;₽</span>
                    </div>
                  </div>
                </div>

                <div class="product__right">
                  <router-link tag="span" :to="'/detail/'+item.product.id" class="product__name">{{ item.product.name }}</router-link>

                  <div class="product__additional-props">
                    <span class="product__additional-prop">арт. {{item.product.artnumber}}</span>
                    <span class="product__additional-prop">{{item.product.stil}}</span>
                  </div>

                  <div class="product__props">
                    <span class="product__package-prop">{{item.product.tip_upakovki}} {{item.product.volume_t}}</span>


                  </div>

                  <div class="product__brand">
                    <div class="product__brand-img-block">
                      <img v-if="item.product.brand.image" class="product__brand-img" :src="item.product.brand.image" :alt="item.product.brand.name">
                    </div>
                    <span class="product__brand-name">{{item.product.brand.name}}</span>
                  </div>

                  <div class="product__controls">
                    <span class="product__simple-count">{{ item.order.quantity}} шт.</span>

                    <span class="product__controls-total">{{ item.order.price*item.order.quantity }}&nbsp;₽</span>
                  </div>
                </div>
              </div>
            </template>
          </div>

          <div class="order-request-detail__requisites requisites-block">
            <div class="requisites-block__items">
              <p class="requisites-block__pair">
                <span class="requisites-block__pair-name">Комментарий к возврату</span>
                <span class="requisites-block__pair-value">{{refund.comment}}</span>
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
</template>

<script>
import ProductItem from '../components/app/ProductItem'

export default {
  data: () => ({
    property: false,
    basket: false,
    statuses: {
      'new': 'order-request-detail__total-short-status--pending',
      'accept': 'order-request-detail__total-short-status--done',
      'cancel': 'order-request-detail__total-short-status--canceled'
    }
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    refund() {
      if (this.$store.getters.refundDetail.property != undefined)
        this.property = this.$store.getters.refundDetail.property;
      if (this.$store.getters.refundDetail.products != undefined)
        this.basket = this.$store.getters.refundDetail.products;
      return this.$store.getters.refundDetail;
    }
  },
  created() {
    this.getDetail();
  },
  components: {ProductItem},
  methods: {
    async getDetail() {
      await this.$store.dispatch({type: 'fetchRefundDetail', id:this.$route.params.id})
    },
    getStatusCode(id) {
      return this.statuses[id];
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
}
</script>

