<template>
  <section class="section cart-forgot" v-if="sliderLoad && forget">
    <div class="site-container">
      <h2 class="section__heading cart-forgot__heading">вы могли забыть</h2>
      <div class="cart-forgot__slider slider">
        <div class="slider__items">
          <swiper :modules="modules" :pagination="true">
            <template v-for="slide in forget">
            <swiper-slide>
              <div class="slider__item product">
                <ProductItem :item="slide"></ProductItem>
              </div>
            </swiper-slide>
            </template>
          </swiper>
        </div>
        <div class="slider__dots"></div>
      </div>
    </div>
  </section>

</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import ProductItem from './ProductItem'

export default {
  props: [

  ],
  data: () => ({
    modules: [Pagination],
    sliderLoad: false,
    forget: false
  }),
  computed: {

  },
  async created() {
    this.forget = await this.getforget();
    this.sliderLoad = true;
  },
  methods: {
    async getforget() {
      await this.$store.dispatch('fetchForget')
      return this.$store.getters.forget;
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    ProductItem
  }
}
</script>