<template>
  <section class="section index-sale" v-if="sliderLoad">
    <div class="site-container">
      <h2 class="section__heading index-new__heading">Новинки</h2>

      <div class="index-sale__slider slider">
        <div class="slider__items">
          <swiper :modules="modules" :pagination="true">
            <template v-for="slide in slides">
            <swiper-slide>
              <div class="slider__item product">
                <ProductItem :item="slide"></ProductItem>
              </div>
            </swiper-slide>
            </template>
          </swiper>
        </div>

        <div class="slider__dots"></div>
      </div>

      <router-link to="/new" class="index-new__btn btn" href="#">Смотреть все новинки</router-link>
    </div>
  </section>

</template>
<script>
import { Swiper, SwiperSlide } from 'swiper/vue';
import 'swiper/css';
import 'swiper/css/pagination';
import { Pagination } from 'swiper';
import ProductItem from './ProductItem'

export default {
  props: [

  ],
  data: () => ({
    modules: [Pagination],
    sliderLoad: false,
    slides: false
  }),
  computed: {

  },
  async created() {
    this.slides = await this.slider();
    this.sliderLoad = true;
  },
  methods: {
    async slider() {
    //  if (this.$store.getters.homeNew === false || this.$store.getters.homeNew == undefined)
        await this.$store.dispatch('fetchHomeNew')
      return this.$store.getters.homeNew;
    }
  },
  components: {
    Swiper,
    SwiperSlide,
    ProductItem
  }
}
</script>