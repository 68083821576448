<template>

    <section class="section cart">
      <div class="site-container">
        <div v-if="summary.quantity > 0" class="cart__total">
          <div class="cart__total-row">
            <span class="cart__total-text">Итого</span>

            <p class="cart__total-prices">
              <span v-if="summary.discount > 0" class="cart__total-price cart__total-price--old">{{ summary.full_price }}&nbsp;₽</span>
              <span class="cart__total-price">{{ summary.price }}&nbsp;₽</span>
            </p>
          </div>

          <div class="cart__total-row">
            <span class="cart__total-products">{{ summary.quantity }} {{ declOfNum( summary.quantity, ['наименование','наименования','наименований']) }}</span>

            <span v-if="summary.discount > 0" class="cart__total-discount">Скидка {{summary.discount}} ₽</span>
          </div>

          <router-link v-if="summary.order_access" to="/order" class="cart__order-btn btn" href="#">Перейти к оформлению</router-link>
          <div v-else>
            Минимальная сумма заказа {{summary.min_price}} руб.
          </div>
        </div>
        <div v-if="summary.quantity == 0" class="cart__total">
          <p>В корзине пока ничего нет</p>
        <router-link to="/catalog" class="cart__order-btn btn" href="#">Перейти в каталог</router-link>
        </div>
        <div class="cart__products">
          <template v-for="item in basket">
            <div v-if="item" class="cart__product product" :class="{'product--disabled':false}">
            <ProductItem :item="item.product" :itembasket="item.basket"></ProductItem>
            </div>
          </template>
        </div>
      </div>
    </section>

    <Forget></Forget>

</template>

<script>
import ProductItem from '../components/app/ProductItem'
import Forget from "../components/app/Forget";
export default {
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    basket() {
      return this.$store.getters.basket;
    },
    summary() {
      return this.$store.getters.flyBasket;
    }
  },
  methods: {
    async showBasket() {
      await this.$store.dispatch('fetchBasket')
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
  created() {
    this.showBasket();
  },
  components: {Forget, ProductItem},
}
</script>

