import axios from 'axios'

export default {
    state: {
        section: [],
        products: [],
        sort: 'name_asc',
        search: '',
        searchResult: 0,
        product: false,
        sections: false
    },
    mutations: {
        setsection(state, data) {
            state.section[data.id] = data.section.items;
        },
        setproducts(state, data) {
            if (!state.products[data.section_id])
                state.products[data.section_id] = [];
            state.products[data.section_id][data.brand_id] = data.products.items;
        },
        setsort(state, data) {
            state.sort = data;
        },
        setsearch(state, data) {
            state.search = data;
        },
        setsearchResult(state, data) {
            state.searchResult = data;
        },
        product(state, data) {
            state.product = data.item;
        },
        sections(state, data) {
            let sections = data.items;

            let sortedObject = Object.keys(sections).sort((a, b) => {
                return sections[a].sort - sections[b].sort
            }).reduce((prev, curr, i) => {
                prev[i] = sections[curr]
                return prev
            }, {});

            state.sections = sortedObject;
        }
    },
    getters: {
        section: (state) => (id) => {
            return state.section[id];
        },
        product: (state) => {
            return state.product;
        },
        sections: (state) => {
            return state.sections;
        },
        products: (state) => (section_id, brand_id) => {
            if (typeof state.products[section_id] == 'object')
                return state.products[section_id][brand_id];
            else
                return [];
        },
        searchResult: (state) => (id) => {
            return state.searchResult;
        }
    },
    actions: {
        async fetchSection({dispatch, commit}, data) {
            let query = '';
            if (data.sort) {
                let arSort = data.sort.split("_");
                query = '?by='+arSort[0]+'&order='+arSort[1];
            }
            if (data.search) {
                if (query)
                    query += '&';
                query += 'search='+data.search;
            }
            let response = await axios('/local/api/catalog/section/'+data.id+query).then(function (response) {
                let section = response.data; // читать тело ответа в формате JSON
                commit('setsection', {section:section, id:data.id});
                commit('setsearchResult', section.all);
            }); // завершается с заголовками ответа

        },
        async fetchProducts({dispatch, commit, state}, data) {
            let query = '';
            if (data.sort) {
                let arSort = data.sort.split("_");
                query = '?by='+arSort[0]+'&order='+arSort[1];
            }
            if (data.search) {
                if (query)
                    query += '&';
                else
                    query = "?";
                query += 'search='+data.search;
            }
            if (data.sort == state.sort
                && data.search == state.search
                && state.products[data.section_id]
                && state.products[data.section_id][data.brand_id])
                return;
            commit('setsort', data.sort);
            commit('setsearch', data.search);
            let response = await axios('/local/api/catalog/products/'+data.section_id+'/'+data.brand_id+query).then(function (response) {
                let products = response.data; // читать тело ответа в формате JSON
                commit('setproducts', {products:products, section_id:data.section_id, brand_id:data.brand_id});
            }); // завершается с заголовками ответа

        },
        addFavorite({dispatch, commit, state}, data) {
            axios.post( '/local/api/catalog/favorite/add',
                {
                    product:data.id
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
                ).then(function(res){
                //delete state.products['favorite']
                //commit('setproducts', {products:products, section_id:data.section_id, brand_id:data.brand_id});
                })
                .catch(function(){

                });

        },
        deleteFavorite({dispatch, commit, state}, data) {
            axios.post( '/local/api/catalog/favorite/remove',
                {
                    product:data.id
                },
                {
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                }
            ).then(function(res){
                //delete state.products['favorite']
                //commit('setproducts', {products:products, section_id:data.section_id, brand_id:data.brand_id});
            })
                .catch(function(){

                });

        },
        async fetchFavorite({dispatch, commit}, data) {
            let query = '';
            if (data.sort) {
                let arSort = data.sort.split("_");
                query = '?by='+arSort[0]+'&order='+arSort[1];
            }
            if (data.search) {
                if (query)
                    query += '&';
                else
                    query = "?";
                query += 'search='+data.search;
            }
            let response = await axios('/local/api/catalog/favorite').then(function (response) {
                let section = response.data;
                commit('setsection', {section:section.section, id:'favorite'});
                for (let brand_id in section.products)
                {
                    commit('setproducts', {products:section.products[brand_id], section_id:'favorite', brand_id:brand_id});
                }
                commit('setsearchResult', section.all);
            }); // завершается с заголовками ответа
        },
        async fetchNew({dispatch, commit}, data) {
            let query = '';
            if (data.sort) {
                let arSort = data.sort.split("_");
                query = '?by='+arSort[0]+'&order='+arSort[1];
            }
            if (data.search) {
                if (query)
                    query += '&';
                else
                    query = "?";
                query += 'search='+data.search;
            }
            let response = await axios('/local/api/catalog/new'+query).then(function (response) {
                let section = response.data; // читать тело ответа в формате JSON
                commit('setsection', {section:section.section, id:'new'});
                for (let brand_id in section.products)
                {
                    commit('setproducts', {products:section.products[brand_id], section_id:'new', brand_id:brand_id});
                }
                commit('setsearchResult', section.all);
                commit('setsort', data.sort);
                commit('setsearch', data.search);
            }); // завершается с заголовками ответа

        },
        async fetchSale({dispatch, commit}, data) {
            let query = '';
            if (data.sort) {
                let arSort = data.sort.split("_");
                query = '?by='+arSort[0]+'&order='+arSort[1];
            }
            if (data.search) {
                if (query)
                    query += '&';
                else
                    query = "?";
                query += 'search='+data.search;
            }
            let response = await axios('/local/api/catalog/sale'+query).then(function (response) {
                let section = response.data; // читать тело ответа в формате JSON
                commit('setsection', {section:section.section, id:'sale'});
                for (let brand_id in section.products)
                {
                    commit('setproducts', {products:section.products[brand_id], section_id:'sale', brand_id:brand_id});
                }
                commit('setsearchResult', section.all);
                commit('setsort', data.sort);
                commit('setsearch', data.search);
            }); // завершается с заголовками ответа

        },
        async fetchProduct({dispatch, commit}, data) {
            let response = await axios('/local/api/catalog/detail/'+data.id).then(function (response) {
                let product = response.data; // читать тело ответа в формате JSON
                commit('product', product);
            }); // завершается с заголовками ответа

        },
        async fetchSections({dispatch, commit}) {
            let response = await axios('/local/api/catalog/sections').then(function (response) {
                let sections = response.data; // читать тело ответа в формате JSON
                commit('sections', sections);
            }); // завершается с заголовками ответа
        },
        clearProduct({dispatch, commi, state}) {
            state.product = false;
        },
    }
}
