<template>
  <main class="main">
    <section class="section">
      <div class="site-container">
        <div class="info-block info-block--success">
          <p>Введите код, который отправлен на вашу почту и укажите новый пароль</p>
        </div>
        <div class="info-block info-block--alert" v-show="error.length>0">
          <h3>Внимание</h3>
          <p v-for="item in error">{{item}}</p>
        </div>
        <form class="form" action="#" method="post" @submit.prevent="changePassword">
          <div class="form__input-block">
            <label class="form__input-label">
              Код<span class="form__input-required"> *</span>
            </label>
            <div class="form__input-wrapper">
              <input v-model="code" required class="form__input input" type="text" placeholder="Введите данные">
            </div>
          </div>

          <div class="form__input-block">
            <label class="form__input-label">
              Новый пароль<span class="form__input-required"> *</span>
            </label>
            <div class="form__input-wrapper">
              <input v-model="password" required class="form__input input" type="password" placeholder="Введите данные">
            </div>
          </div>

          <div class="form__input-block">
            <label class="form__input-label">
              Повторите новый пароль<span class="form__input-required"> *</span>
            </label>
            <div class="form__input-wrapper">
              <input v-model="password_confirm" required class="form__input input" type="password" placeholder="Введите данные">
            </div>
          </div>

          <button class="form__submit-btn btn" type="submit">Сохранить изменения</button>
        </form>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";

export default {
  props: [
  ],
  data: () => ({
    error: [],
    code: '',
    password: '',
    password_confirm: '',
    email: ''
  }),
  created() {

  },
  mounted() {
    this.email = this.$route.query.email;
    this.$store.dispatch('Loader');
  },
  methods: {
    changePassword() {
      this.error = [];

      if (!this.code)
        this.error.push('Введите код');
      if (!this.password)
        this.error.push('Введите пароль');
      if (!this.password_confirm)
        this.error.push('Введите подтверждение пароля');
      if (this.password != this.password_confirm)
        this.error.push('Введённые пароли не совпадают');
      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }

      let formData = new FormData();
      formData.append('email', this.email);
      formData.append('code', this.code);
      formData.append('password', this.password);
      formData.append('password_confirm', this.password_confirm);
      let _this = this;
      axios.post( '/local/api/password/new',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        console.log(res)
        console.log('SUCCESS!!');
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200)
          this.$router.push('/login');
      })
          .catch(function(){
            console.log('FAILURE!!');
          });


    },
  },
  components: {

  }
}
</script>

