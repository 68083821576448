import axios from "axios";
export default {
    state: {
        fly_basket: {},
        basket_items: {},
        basket: [],
        forget: false
    },
    mutations: {
        fly_basket(state, slider) {
            state.fly_basket = slider.item;
            state.basket_items = slider.items;
            if (state.basket) {
                let item = '';
                let i = false;
                for (i in state.basket) {
                    item = state.basket[i];
                    if (!state.basket_items[item.basket.product_id]) {
                        delete state.basket[i]
                    }
                }
            }
        },
        basket(state, slider) {
            let item = '';
            let i = false;
            for (i in slider.items) {
                item = slider.items[i];
                state.basket_items[item.basket.product_id] = item.basket;
            }
            state.fly_basket = slider.item;
            state.basket = slider.items;
        },
        add_basket(state, item) {
            state.basket.push(item)
        },
        forget(state, forget) {
            state.forget = forget.items
        }
    },
    getters: {
        flyBasket: s => s.fly_basket,
        basket: s => s.basket,
        forget: s => s.forget,
        inBasket: (state) => (id) => {
            if (typeof state.basket_items == 'object' && typeof state.basket_items[id] == 'object')
                return state.basket_items[id].quantity;
            else
                return 0;
        }
    },
    actions: {
        async fetchFlyBasket({dispatch, commit}) {
            let response = await axios('/local/api/basket/summary').then(function(response) {
                let basket = response.data; // читать тело ответа в формате JSON
                commit('fly_basket', basket);
            }); // завершается с заголовками ответа

        },
        async fetchBasket({dispatch, commit}) {
            let response = await axios('/local/api/basket/list').then(function (response) {
                let basket = response.data; // читать тело ответа в формате JSON
                commit('basket', basket);
            }); // завершается с заголовками ответа

        },
        async fetchForget({dispatch, commit}) {
            let response = await axios('/local/api/basket/forget').then(function (response) {
                let forget = response.data; // читать тело ответа в формате JSON
                commit('forget', forget);
            }); // завершается с заголовками ответа

        },
        async addBasket({dispatch, commit}, data) {
            let response = await axios.post('/local/api/basket/add', {
                id: data.id,
                quantity: data.quantity
            }).then(function (basket) {
                commit('add_basket', basket.data.product);
                commit('fly_basket', basket.data.summary);
            }); // завершается с заголовками ответа
        },
        async updateBasket({dispatch, commit, state}, data) {
            let id = state.basket_items[data.id].id;
            let response = await axios.post('/local/api/basket/update', {
                id: id,
                quantity: data.quantity
            }).then(function (basket) {
                commit('fly_basket', basket.data.summary);
            }); // завершается с заголовками ответа
           // let basket = await response.json(); // читать тело ответа в формате JSON
        },
        async deleteBasket({dispatch, commit, state}, data) {
            let id = state.basket_items[data.id].id;
            let response = await axios.post('/local/api/basket/delete', {
                id: id
            }).then(function (basket) {
                commit('fly_basket', basket.data.summary);
            }).catch(function (error) {

            }); // завершается с заголовками ответа
            // let basket = await response.json(); // читать тело ответа в формате JSON
        }
    }
}
