<template>
  <main class="main">
    <section class="section">
      <div class="site-container">
        <div class="info-block info-block--success">
          <p>На указанную почту будет выслан код для сброса пароля</p>
        </div>
        <div class="info-block info-block--alert" v-show="error.length>0">
          <h3>Внимание</h3>
          <p v-for="item in error">{{item}}</p>
        </div>

        <div class="form-wrapper">
          <form class="form" action="#" method="post" @submit.prevent="request">
            <div class="form__input-block">
              <label class="form__input-label" for="formInput1">
                E-mail<span class="form__input-required"> *</span>
              </label>
              <div class="form__input-wrapper">
                <input v-model="email" required class="form__input input" type="email" id="formInput1" placeholder="Введите данные">
              </div>
            </div>

            <button class="form__submit-btn btn" type="submit">Отправить</button>
          </form>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import axios from "axios";

export default {
  props: [
  ],
  data: () => ({
    email: '',
    error: []
  }),
  created() {

  },
  mounted() {

    this.$store.dispatch('Loader');
  },
  beforeRouteLeave(to, from) {

    // called when the route that renders this component is about to be navigated away from.
    // As with `beforeRouteUpdate`, it has access to `this` component instance.
  },
  methods: {
    request() {
      this.error = [];

      if (!this.email)
        this.error.push('Введите ваш Email');
      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }

      let formData = new FormData();
      formData.append('email', this.email);
      let _this = this;
      axios.post( '/local/api/password/request',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        console.log(res)
        console.log('SUCCESS!!');
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          _this.$router.push({path: '/new-password', query: { email: _this.email }});
        }
      })
          .catch(function(){
            console.log('FAILURE!!');
          });
    },
  },
  components: {

  }
}
</script>

