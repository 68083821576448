<template>
  <main class="main">
    <section v-if="!success" class="section">
      <div class="site-container">
        <div class="info-block info-block--alert" v-show="error.length>0">
          <h3>Внимание</h3>
          <p v-for="item in error">{{item}}</p>
        </div>
        <div v-show="success" class="info-block info-block--success">
          <p>{{ success }}</p>
        </div>
        <form class="form" action="#" method="post" @submit.prevent="send">
          <div class="form__input-block">
            <label class="form__input-label">
              E-mail<span class="form__input-required"> *</span>
            </label>
            <div class="form__input-wrapper">
              <input v-model="email" required class="form__input input" type="email" placeholder="Введите данные">
            </div>
          </div>

          <div class="form__input-block">
            <input v-model="dekalitr" id="dekalitr" class="form__input form__input--checkbox hidden-input" type="checkbox">
            <label class="form__input-label form__input-label--checkbox" for="dekalitr" >
              По декалитрам
            </label>
          </div>

          <div class="form__input-block">
            <label class="form__input-label">
              Выберите период времени<span class="form__input-required"> *</span>
            </label>
            <div v-if="dekalitr" class="form__input-wrapper form__input-wrapper--radio">
              <div v-for="(item, index) in periods" class="form__radio-wrapper">
                <input v-model="period" :value="item" :id="'p'+index" class="form__input form__input--radio hidden-input" type="radio">
                <label class="form__radio-label" :for="'p'+index">{{ item }}</label>
              </div>
            </div>
            <div v-if="!dekalitr" class="form__input-wrapper">
              <div class="form__input form__input--half">
                <VueDatePicker placeholder="Начало пероида" v-model="from" locale="ru" format="dd/MM/yyyy" date-picker auto-apply></VueDatePicker>
              </div>

              <div class="form__input form__input--half">
                <VueDatePicker placeholder="Конце пероида" v-model="to" locale="ru" format="dd/MM/yyyy" date-picker auto-apply></VueDatePicker>
              </div>
            </div>
          </div>

          <div class="form__input-block">
            <label class="form__input-label">
              Комментарий
            </label>
            <div class="form__input-wrapper">
              <textarea v-model="comment" class="form__input form__input--textarea textarea" placeholder="Напишите, что нам нужно знать"></textarea>
            </div>
          </div>

          <button class="form__submit-btn btn" type="submit">Запросить акт сверки</button>
        </form>
      </div>
    </section>

    <section v-if="success" class="section">
      <div class="site-container">
        <div class="cabinet-request-accepted">
          <img class="cabinet-request-accepted__img" src="img/img-request-accepted-new.png" alt="Ваша заявка принята">

          <h2 class="cabinet-request-accepted__text">
            Спасибо,<br>
            ваша заявка принята.<br>
            Вы получите ответ на почту
          </h2>

          <div class="cabinet-request-accepted__links links-block">
            <router-link to="/catalog" class="links-block__link btn" href="#">Каталог</router-link>
            <router-link to="/" class="links-block__link btn btn--non-target" href="#">Главная</router-link>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
import VueDatePicker from '@vuepic/vue-datepicker';
import '@vuepic/vue-datepicker/dist/main.css'
import axios from "axios";

export default {
  data: () =>  ({
    from: '',
    to:'',
    email: '',
    dekalitr: false,
    period: '',
    comment: '',
    error: false,
    success: false,
    periods: []
  }),
  mounted() {
    this.$store.dispatch('Loader');
    this.email = this.$store.getters.user.EMAIL;
    let now = new Date();
    let year = now.getFullYear();
    let year_p = year - 1;
    let q = (now.getMonth()+1)/4;
    console.log(now.getMonth())
    if (q < 1) {
      this.periods.push('IV '+year_p);
      this.periods.push('III '+year_p);
      this.periods.push('II '+year_p);
      this.periods.push('I '+year_p);
    } else if (q < 2) {
      this.periods.push('I '+year);
      this.periods.push('IV '+year_p);
      this.periods.push('III '+year_p);
      this.periods.push('II '+year_p);

    } else if (q < 3) {
      this.periods.push('II '+year);
      this.periods.push('I '+year);
      this.periods.push('IV '+year_p);
      this.periods.push('III '+year_p);

    } else {
      this.periods.push('III '+year);
      this.periods.push('II '+year);
      this.periods.push('I '+year);
      this.periods.push('IV '+year_p);
    }
  },
  components: {VueDatePicker},
  methods: {
    send() {
      this.error = [];
      this.success = false;

      if (this.dekalitr && !this.period)
        this.error.push('Выберите период');
      if (!this.dekalitr && !this.from)
        this.error.push('Введите начало периода');
      if (!this.dekalitr && !this.to)
        this.error.push('Введите конец периода');
      if (!this.dekalitr && this.from.getTime() > this.to.getTime()) {
        this.error.push('Ошибка в периоде');
      }
      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }

      let formData = new FormData();
      formData.append('email', this.email);
      formData.append('comment', this.comment);
      formData.append('dekalitr', this.dekalitr);
      if (this.dekalitr) {
        formData.append('period', this.period);
      }
      else {
        formData.append('from', this.from.getTime()/1000);
        formData.append('to', this.to.getTime()/1000);
      }
      let _this = this;
      axios.post( '/local/api/personal/reconciliation/add',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        console.log(res)
        console.log('SUCCESS!!');
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          _this.success = true;
          _this.from = '';
          _this.to = '';
          _this.period = false;
          _this.comment = '';
        }
      })
          .catch(function(){
            console.log('FAILURE!!');
          });


    },
  },
}
</script>

