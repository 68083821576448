<template>
  <section v-if="refund && !success" class="section">
    <div class="site-container">
      <div class="info-block info-block--success">
        <p>
          Для каждого наименования, которое нужно вернуть, отметьте количество единиц для возврата. Если товар не требует возврата - оставьте 0 шт.
        </p>
      </div>
      <div class="info-block info-block--alert" v-show="error.length>0">
        <h3>Внимание</h3>
        <p v-for="item in error">{{item}}</p>
      </div>
      <form class="form" action="#" method="post" @submit.prevent="send">
        <div class="form__products">
          <div v-for="item in basket" class="form__product product">
            <div class="product__left">
              <div class="product__img-block">
                <img v-if="item.product.image" class="product__img" :src="item.product.image">
              </div>

              <div class="product__left-bottom">
                <span class="product__rating">{{item.product.untappd}}</span>

                <div class="product__prices">
                  <span class="product__price">{{item.order.price}}&nbsp;₽</span>
                </div>
              </div>
            </div>

            <div class="product__right">
              <router-link tag="span" :to="'/detail/'+item.product.id" class="product__name">{{item.product.name}}</router-link>

              <div class="product__additional-props">
                <span class="product__additional-prop">арт. {{item.product.artnumber}}</span>
                <span class="product__additional-prop">Наим.ЕГАИС, {{item.product.egais}}</span>
              </div>

              <div class="product__props">
                <span class="product__package-prop">{{item.product.tip_upakovki}} {{item.product.volume_t}}</span>
              </div>

              <div class="product__brand">
                <div class="product__brand-img-block">
                  <img v-if="item.product.brand.image" class="product__brand-img" :src="item.product.brand.image" :alt="item.product.brand.name">
                </div>
                <span class="product__brand-name">{{item.product.brand.name}}</span>
              </div>

              <div class="product__controls">
                <div class="product__controls-btns">
                  <button @click="quantity[item.product.id]>0?quantity[item.product.id]--:false" class="product__controls-btn product__controls-btn--minus " :class="{'product__controls-btn--disabled':quantity[item.product.id] == 0}" type="button"></button>
                  <input class="product__controls-counter" type="number" disabled="" v-model="quantity[item.product.id]">
                  <button @click="quantity[item.product.id] < item.order.quantity?quantity[item.product.id]++:false" class="product__controls-btn product__controls-btn--plus" :class="{'product__controls-btn--disabled':quantity[item.product.id] >= item.order.quantity}" type="button"></button>
                </div>

                <span class="product__controls-total" :class="{'product__controls-total--hidden':quantity[item.product.id]==0}">{{ quantity[item.product.id]*item.order.price }}&nbsp;₽</span>
              </div>
            </div>
          </div>
        </div>

        <div class="form__input-block">
          <label class="form__input-label" for="formInput9">
            Комментарий
          </label>
          <div class="form__input-wrapper">
            <textarea v-model="comment" class="form__input form__input--textarea textarea" placeholder="Напишите, что нам нужно знать"></textarea>
          </div>
        </div>

        <div class="form__sum">
          <span class="form__sum--name">Итого</span>
          <span class="form__sum-value">{{ sum }}&nbsp;₽</span>
        </div>

        <button class="form__submit-btn btn" type="submit">Завершить оформление</button>
      </form>
    </div>
  </section>

  <section v-if="success" class="section">
    <div class="site-container">
      <div class="info-block info-block--success">
        <p>
          Спасибо, ваш запрос на возврат оформлен. Ожидайте информации по возврату на электронную почту.
        </p>
      </div>

      <HelpManager></HelpManager>

      <div class="links-block">
        <router-link to="/" class="links-block__link btn btn--non-target" href="#">Главная</router-link>
        <router-link to="/personal" class="links-block__link btn" href="#">Личный кабинет</router-link>
      </div>

      <div class="requisites-block">
        <h2 class="requisites-block__heading section__heading">Информация о возврате</h2>

        <div class="requisites-block__items">
          <p class="requisites-block__pair">
            <span class="requisites-block__pair-name">Сумма возврата</span>
            <span class="requisites-block__pair-value requisites-block__pair-value--bold">{{ sum }}&nbsp;₽</span>
          </p>

          <p class="requisites-block__pair">
            <span class="requisites-block__pair-name">Комментарий к возврату</span>
            <span class="requisites-block__pair-value">{{ comment }}</span>
          </p>
        </div>
      </div>
    </div>
  </section>
</template>

<script>
import ProductItem from '../components/app/ProductItem'
import axios from "axios";
import HelpManager from "../components/app/HelpManager"

export default {
  data: () => ({
    basket: false,
    quantity: [],
    error: false,
    success: false,
    comment: ''
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    refund() {
      if (this.$store.getters.orderDetail.basket != undefined) {
        this.basket = this.$store.getters.orderDetail.basket;
        for (let i in this.basket) {
          this.quantity[this.basket[i].product.id] = 0;
        }
      }
      return this.$store.getters.orderDetail;
    },
    sum() {
      let sum = 0;
      for (let i in this.basket) {
        sum += this.quantity[this.basket[i].product.id] * this.basket[i].order.price;
      }
      return sum;
    }
  },
  created() {
    this.getDetail();
  },
  components: {ProductItem, HelpManager},
  methods: {
    async getDetail() {
      await this.$store.dispatch({type: 'fetchOrderDetail', id:this.$route.params.id})
    },
    send() {
      this.error = [];
      this.success = false;

      if (this.sum == 0)
        this.error.push('Выберите товары для возврата');
      if (this.error.length > 0) {
        $(window).scrollTop($(".info-block--alert").offset().top);
        return;
      }

      let formData = new FormData();
      for (let i in this.quantity) {
        formData.append('ids['+i+']', this.quantity[i]);

      }
      formData.append('comment', this.comment);
      formData.append('order_id', this.$route.params.id);

      let _this = this;
      axios.post( '/local/api/personal/refund/add',
          formData,
          {
            headers: {
              'Content-Type': 'multipart/form-data'
            }
          }
      ).then(function(res){
        console.log(res)
        console.log('SUCCESS!!');
        if (res.data.error) {
          _this.error = res.data.error;
          $(window).scrollTop($(".info-block--alert").offset().top);
        }
        if (res.data.status == 200) {
          _this.success = true;
        }
      })
          .catch(function(){
            console.log('FAILURE!!');
          });


    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    }
  },
}
</script>

