<template>

    <section v-if="refund" class="section">
      <div class="site-container">
        <div class="orders-requests">
          <div class="orders-requests__list">
            <template v-for="item in refund">
            <router-link :to="'/refund/'+item.id" class="orders-requests__item" :class="{'orders-requests__item--pale':item.status_id=='cancel'}" href="#">
              <div class="orders-requests__item-row">
                <span class="orders-requests__item-number">{{ item.date_create }}</span>
                <span class="orders-requests__item-status" :class="getStatusCode(item.status_id)">{{ item.status }}</span>
              </div>

              <div class="orders-requests__item-row">
                <span class="orders-requests__item-date">{{item.quantity}} {{ declOfNum(item.quantity, ['единица','единицы','единиц']) }}</span>
                <span class="orders-requests__item-price">{{ item.sum }}</span>
              </div>
            </router-link>
            </template>
          </div>
        </div>
        <p v-if="refund.length==0">У вас пока нет запроов на возврат</p>
      </div>
    </section>
</template>

<script>
export default {
  data: () => ({
    property: false,
    basket: false,
    statuses: {
      'new': 'orders-requests__item-status--pending',
      'cancel': 'orders-requests__item-status--canceled',
      'accept': 'orders-requests__item-status--done'
    }
  }),
  mounted() {
    this.$store.dispatch('Loader');
  },
  computed: {
    refund() {
      return this.$store.getters.refund;
    }
  },
  methods: {
    async showRefund() {
      await this.$store.dispatch('fetchRefund')
    },
    declOfNum(n, text_forms) {
      n = Math.abs(n) % 100;
      let n1 = n % 10;
      if (n > 10 && n < 20) { return text_forms[2]; }
      if (n1 > 1 && n1 < 5) { return text_forms[1]; }
      if (n1 == 1) { return text_forms[0]; }
      return text_forms[2];
    },
    getStatusCode(id) {
      return this.statuses[id];
    },
  },
  created() {
    this.showRefund();
  },
}
</script>

