import axios from "axios";

export default {
    state: {
        userInfo: false
    },
    mutations: {
        userInfo(state, userInfo) {
            state.userInfo = userInfo;
        }
    },
    getters: {
        userInfo: s => s.userInfo
    },
    actions: {
        async fetchUserInfo({dispatch, commit, state}) {
            let response = await axios('/local/api/personal/profile').then (function (res) {
                let userInfo = res.data;
                commit('userInfo', userInfo);
            }); // завершается с заголовками ответа
            //let userInfo = await response.json(); // читать тело ответа в формате JSON

        }
    }
}
